@import url('https://fonts.googleapis.com/css2?family=Mada:wght@200..900&display=swap');


@font-face {
  font-family: Gregular; //fontweight : 400
  src: url(../src/font/Gellix-Regular.woff);
}

@font-face {
  font-family: Gmedium; //fontweight : 500
  src: url(../src/font/Gellix-Medium.woff);
}

@font-face {
  font-family: Gsemibold; //fontweight : 600
  src: url(../src/font/Gellix-SemiBold.woff);
}

@font-face {
  font-family: Gbold; //fontweight : 700
  src: url(../src/font/Gellix-Bold.woff);
}

@font-face {
  font-family: Gextrabold; //fontweight : 800
  src: url(../src/font/Gellix-ExtraBold.woff);
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--bg-100, #111);
  min-height: 100vh;
  font-family: "Mada", sans-serif !important;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}



button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}

.btn-pink{
  color: #FF0083;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  border-radius: 56px;
  background: #FF0083;
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  white-space: nowrap;
  border: 1px solid #FF0083;
}

.btn-transparent {
  color: #FF0083;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  border-radius: 56px;
  border: 1px solid #FF0083;
  display: flex;
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  white-space: nowrap;
}

.custom_container {
  max-width: 1350px;
  margin: 0 auto;
  padding: 0px 15px;
  width: 100%;
}


section.tokenstaking {

  z-index: 9 !important
}




.categorymodal{

  .modal-header .btn-close {
    background: url("../src/Assests/cross.svg");
    background-repeat: no-repeat;
    box-shadow: unset;
    opacity: unset;
    height: 30px;
    width: 20px;
    cursor: pointer;
    margin-top: 2px;
  }
  
  .modal-dialog {
    .modal-content {
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(120, 120, 120, 0.03) 100%);
      border: 1px solid #FFFFFF1A;
      backdrop-filter: blur(160px);
      .modal-header {
        border: unset;
        // cursor: pointer;
      }
      .modal-body {
        padding: 0px;
        .modal__body {
          display: flex;
          flex-direction: column;
          align-items: center;
          h5 {
            color: #FFF;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 24px */
            padding-top: 30px;
          }
          p {
            color: #BCBCBC;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
          }
          h2 {
            color: #FF0083;
            text-align: center;
            padding-bottom: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          button {
            color: #FFF;
            border: unset;
            font-size: 16px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 218px;
            padding: 15px 0px;
            border-radius: 120px;
            background: #FF0083;
            margin-bottom: 54px;
          }
          // button:hover{
          //   color: #FFFFFF;
          //   background-color: var(--lightPrimary);
          //   border-color: var(--lightPrimary);
          // }
        }
      }
    }
  }
}
