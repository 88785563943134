.newbanner {
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    margin-bottom: 163px;

    .bannerbgimg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
    }

    .explorepara {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 3px;

        .greyclr {
            color: var(--White, var(--White, #FFF));
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 8px;
            z-index: 9;
            position: relative;
            opacity: 0.5;
        }

        .pinkclr {
            color: var(--Accent, #FF0083);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            position: relative;
            z-index: 9;
        }
    }

    .unlockhead {
        color: var(--White, var(--White, #FFF));
        text-align: center;
        font-size: 70px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
        margin: 0 auto 8px;
        max-width: 700px;
        width: 100%;
        z-index: 9;
        position: relative;

        .pinkclr {
            color: var(--Accent, var(--White, #FF0083));
            font-size: 70px;
            font-style: normal;
            font-weight: 900;
            line-height: 110%;
        }
    }

    .usepara {
        color: var(--White, var(--White, #FFF));
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 30px;
        z-index: 9;
        position: relative;
    }

    .pinkbtn {
        display: flex;
        width: 288px;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 100px;
        border: 1px solid var(--Accent, #FF0083);
        background: var(--Accent, #FF0083);
        color: var(--White, var(--White, #FFF));
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        gap: 8px;
        margin: 0 auto 25px;
        z-index: 9;
        position: relative;
    }
}

@media(max-width:992px){
    .newbanner .unlockhead{
        font-size: 35px !important;
        padding-left: 15px;
        padding-right: 15px;
        .pinkclr{
            font-size: 35px !important;
        }
    }
    .newbanner{
        margin-bottom: 50px;
    }
    .newbanner .usepara{
        padding-left: 15px;
        padding-right: 15px;
    }
    .newbanner{
        height: 350px;
    }
}