.mainpower {
    padding-bottom: 244px;
    position: relative;

    .mainpowerbg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
    }

    .powerrightshade {
        position: absolute;
        top: 20px;
        right: -371px;
        max-width: 485.14px;
        width: 100%;
        height: 753.734px;
        border-radius: 753.734px;
        opacity: 0.1;
        background: var(--Accent, #FF0083);
        filter: blur(173.76002502441406px);
        transform: rotate(-30deg);
    }

    .powerleftshade {
        position: absolute;
        top: 20px;
        left: -244px;
        max-width: 485.14px;
        width: 100%;
        height: 753.734px;
        border-radius: 753.734px;
        opacity: 0.1;
        background: var(--Accent, #FF0083);
        filter: blur(173.76002502441406px);
        transform: rotate(-30deg);
    }

    .powerhead {
        color: var(--White, var(--White, #FFF));
        text-align: center;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        max-width: 842px;
        width: 100%;
        margin: 0 auto 36px;
        position: relative;
        z-index: 9;

        .pinkclr {
            color: var(--Accent, var(--White, #FF0083));
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }
    }

    .powerpara {
        color: var(--White, var(--White, #FFF));
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        max-width: 490px;
        width: 100%;
        margin: 0 auto;
    }
}

@media(max-width:992px){
    .mainpower{
        padding-bottom: 70px;
    }
    .mainpower .powerhead{
        font-size: 40px;
        .pinkclr{
            font-size: 40px;
        }
    }
}